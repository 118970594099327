import '../project'
import BaseUtils from './base/_utils'
import { AccountType, AppState, CreditType, Status } from '../state-type'
import store from 'common/store'
const geolib = require('geolib')
const Utils = (global.Utils = Object.assign({}, BaseUtils, {
  getRelativeContentUrl: (url: string) => {
    return url.replace('https://www.legalutopia.co.uk', '')
  },

  filterTruths: (filters: { key: string; value: boolean }[] = []) => {
    const res = {}

    filters.map((filter) => {
      if (filter.value) {
        res[filter.key] = true
      }
    })

    return res
  },

  formatMoneyFromPence(pence?: number) {
    pence = pence || 0
    return (pence ? pence / 100 : 0).toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP',
    })
  },

  convertYesNoUnknown(e) {
    const v = Utils.safeParseEventValue(e)
    if (v === 'true') {
      return true
    } else if (v === 'false') {
      return false
    }
    return null
  },

  getLegalServicesUrl(
    appends = {
      lawyerAppend: '',
      barristerAppend: '',
      chamberAppend: '',
    },
  ) {
    const _store = store()
    const profileType = _store.store.getState().profile?.account_type
    switch (profileType) {
      case AccountType.BARRISTER:
        return 'barristers' + appends.barristerAppend
        break
      case AccountType.CHAMBER:
        return 'chambers' + appends.chamberAppend
        break
      default:
        return 'lawyers' + appends.lawyerAppend
        break
    }
  },

  getStatusName(status: Status) {
    switch (status) {
      case Status.ACCEPTED:
        return 'Accepted'
      case Status.CANCELLED:
        return 'Cancelled'
      case Status.DECLINED:
        return 'Declined'
      default:
        return 'Pending'
    }
  },

  getDistance(lat, lng, lat2, lng2) {
    if (!lat || !lng || !lat2 || !lng2) {
      return 0
    }
    const distanceMeters = geolib.getDistance(
      { latitude: lat, longitude: lng },
      {
        latitude: lat2,
        longitude: lng2,
      },
    )
    return parseInt(geolib.convertDistance(distanceMeters, 'mi'))
  },

  formatTime(v) {
    let mins
    let hours
    if (v % 1 === 0.5) {
      mins = '30'
      hours = v - 0.5
    } else if (v % 1 === 0) {
      hours = v
      mins = 0
    }
    return Utils.prefix0(hours) + ':' + Utils.prefix0(mins)
  },

  prefix0(v) {
    if (!v) return '00'
    else if (v < 10) return '0' + `${v}`
    return `${v}`
  },

  timeOfDay(hr) {
    if (hr < 12) {
      return 'Morning'
    } else if (hr >= 12 && hr <= 17) {
      return 'Afternoon'
    }
    return 'Evening'
  },

  nFormatter(num, digits) {
    const si = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ]
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    let i
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
  },

  getCredits: (creditType: CreditType, profile: AppState['resultCredits']) => {
    switch (creditType) {
      default:
        profile.total
    }
  },

  capitalizeFirstLetter: (s: string) => {
    if (s) {
      return s[0].toUpperCase() + s.slice(1)
    }
    return ''
  },

  getNameInitials: (fullName: string) => {
    let names = fullName.split(' '),
      initials = names[0].substring(0, 1).toUpperCase()

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }
    return initials
  },

  getUserType: (type: AccountType) => {
    if (type === AccountType.BARRISTER) {
      return 'barrister'
    } else if (type === AccountType.LAWYER) {
      return 'lawyer'
    } else if (type === AccountType.BUSINESS) {
      return 'office'
    }
    return null
  },

  getProfileTypeStoreKey: (type: AccountType) => {
    if (type === AccountType.BARRISTER) {
      return 'barristers'
    } else if (type === AccountType.LAWYER) {
      return 'lawyerProfiles'
    } else if (type === AccountType.BUSINESS) {
      return 'offices'
    }
    return null
  },

  getAge: (birthDate: string) => {
    const currentTime = new Date().getTime()
    const birthDateTime = new Date(birthDate).getTime()
    const difference = currentTime - birthDateTime
    const ageInYears = Math.trunc(difference / (1000 * 60 * 60 * 24 * 365))
    return ageInYears
  },

  openInNewTab: (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  },

  fileNameFromPath: (path: string) => {
    if (path) {
      const url1 = path.substring(0, path.indexOf('?X'))
      const fileName = url1.substring(url1.lastIndexOf('/') + 1)
      return fileName
    }
  },
  getWindowDimensions: () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  },
}))

export default Utils
