import { ReactNode, useState } from 'react'
import { useAuth } from '../../providers/useAuth'
import useProfile from '../../providers/useProfile'
import {
  AppBar,
  Menu,
  MenuItem,
  IconButton,
  Box,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { useRouter } from 'next/router'
import classNames from 'classnames'

type NavbarProps = {
  dropdownOptions: Array<{
    isLink?: boolean
    label: string
    value?: string
    icon?: string
    onClick?: () => void
  }>
  children?: ReactNode
  isSlimDropdown?: boolean
  className?: string
}

export const Navbar = ({
  dropdownOptions,
  children,
  isSlimDropdown,
  className,
}: NavbarProps) => {
  const { profile } = useProfile()
  const { logout } = useAuth()
  const [anchorEl, setAnchorEl] = useState(null)
  const router = useRouter()

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (option) => {
    if (option.isLink && option.value) {
      router.push(option.value)
    } else if (option.onClick) {
      option.onClick()
    }
    handleClose()
  }

  return (
    <AppBar position='static' color='transparent' elevation={0}>
      {children}
      <Box>
        <IconButton
          edge='end'
          color='inherit'
          aria-label='account of current user'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleMenu}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id='menu-appbar'
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {dropdownOptions.map((option, index) => (
            <MenuItem key={index} onClick={() => handleMenuItemClick(option)}>
              {option.icon && (
                <ListItemIcon>
                  <img
                    src={option.icon}
                    alt={option.label}
                    style={{ width: 24, height: 24 }}
                  />
                </ListItemIcon>
              )}
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
          <MenuItem
            onClick={() => {
              logout({ id: profile?.id })
              handleClose()
            }}
          >
            Sign Out
          </MenuItem>
        </Menu>
      </Box>
    </AppBar>
  )
}
