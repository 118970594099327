import { Height } from '@mui/icons-material'
import { createTheme } from '@mui/material/styles'
import { RiFontSize } from 'react-icons/ri'
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'large-primary': true
    'large-secondary': true
    'large-tertiary': true
    'large-squareIcon': true
    'regular-primary': true
    'regular-secondary': true
    'regular-tertiary': true
    'small-primary': true
    'small-secondary': true
    'small-tertiary': true
    'small-icon': true
    'link-large': true
    'link-small': true
  }
}
declare module '@mui/material/TextField' {
  interface TextFieldPropsVariantOverrides {
    'input-small': true
    'input-regular': true
    'input-large': true
  }
}
declare module '@mui/material/Select' {
  interface SelectPropsVariantOverrides {
    'dropdown-small': true
    'dropdown-regular': true
  }
}
const theme = createTheme({
  typography: {
    fontFamily: '"DM Sans", sans-serif',
  },
  palette: {
    primary: {
      main: '#020251',
    },
    secondary: {
      main: '#37A2BA',
    },
    tertiary: {
      main: '#8DD8EB',
    },
    quaternary: {
      main: '#BBD2D7',
    },
  },
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          '& .MuiGrid-root': {
            paddingTop: '0px',
          },
        },
        item: {
          paddingTop: '0px', // Override padding-top to 0px for all Grid items
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // height: '36px',
          '& .MuiInputBase-input': {
            fontSize: '18px',
            padding: '9px 12px 3px 12px',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          },

          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'gray',
              FontSize: '10px',
            },
            '&:hover fieldset': {
              borderColor: '#4FC3C8',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#4FC3C8',
            },
          },
          '& .MuiInputLabel-root': {
            left: '14px', // Add left padding to the label
            top: '26px', // Center the label vertically
            fontSize: '14px',
            transform: 'translate(0, -100%) scale(1)', // Ensure vertical centering
            transition: 'top 0.2s ease, transform 0.2s ease', // Smooth transition when the label moves up
            color: 'gray',
            '&.MuiInputLabel-shrink': {
              top: '18%', // Move the label to the top when the field has content or is focused
              transform: 'translate(0, -50%) scale(0.857)', // Shrink and move up when focused or when content exists
            },
            '&.Mui-focused': {
              top: '18%', // Move label to the top when focused
              transform: 'translate(0, -50%) scale(0.857)', // Move the label up
              color: '#4FC3C8',
            },
            '&.Mui-error': {
              color: '#d32f2f',
            },
            '&.Mui-error.Mui-focused': {
              color: '#4FC3C8',
            },
          },
          '& .MuiAutocomplete-inputRoot': {
            height: '45px',
            // margin:'3px 39px 3px 9px'
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          // height: '44px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#4FC3C8',
            // Remove the "notch" effect on focus
            '& legend': {
              display: 'none', // Disable the legend that creates the open notch
            },
          },
        },
      },
    },
    // MuiInputBase: {
    //   styleOverrides: {
    //     input: {
    //       '&[type=number]': {
    //         // Ensure number inputs have the default shrink behavior
    //         '& .MuiInputLabel-root': {
    //           // top: '0', // Force the label to the top for number inputs
    //           // transform: 'translate(0, -100%) scale(0.75)', // Match the default scale behavior
    //           // left: '0', // Add left padding to the label
    //           top: '15px', // Center the label vertically
    //           transform: 'translate(0, -100%) scale(0.75)', // Ensure vertical centering
    //           '&.Mui-focused': {
    //             top: '20px', // Move label to the top when focused
    //             transform: 'translate(0, -100%) scale(0.75)', // Move the label up
    //             color: '#4FC3C8',
    //           },
    //         },
    //       },
    //       '&[type=date]': {
    //         // Ensure date inputs have the default shrink behavior
    //         '& .MuiInputLabel-root': {
    //           top: '0', // Force the label to the top for date inputs
    //           transform: 'translate(0, -100%) scale(0.75)', // Match the default scale behavior
    //           '&.Mui-focused': {
    //             top: '15px', // Move label to the top when focused
    //             transform: 'translate(0, -100%) scale(0.75)', // Move the label up
    //             color: '#4FC3C8',
    //           },
    //         },
    //       },
    //     },
    //   },
    // },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: '#d32f2f',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '20px',
          fontSize: '0.875rem',
          borderRadius: '16px',
          marginTop: '3px',
          marginBottom: '3px',
          padding: '0 6px',
        },
        label: {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
        deleteIcon: {
          fontSize: '16px',
        },
      },
    },
  },
})

export default theme
